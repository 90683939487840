import React from 'react'
import { graphql, Script } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import Header from '@solid-ui-blocks/Header/Block01'
import Tabs from '@solid-ui-components/Tabs'
import Hero from '@solid-ui-blocks/Hero/Block01'
// import Stats from '@solid-ui-blocks/Stats/Block01'
import Features from '@solid-ui-blocks/Features/Block06'
import HowItWorks from '@solid-ui-blocks/FeaturesWithPhoto/Block04'
import FeatureTabOne from '@solid-ui-blocks/FeaturesWithPhoto/Block05'
import FeatureTabTwo from '@solid-ui-blocks/FeaturesWithPhoto/Block01'
import FeatureTabThree from '@solid-ui-blocks/FeaturesWithPhoto/Block07'
// import Testimonials from '@solid-ui-blocks/Testimonials/Block03'
import Companies from '@solid-ui-blocks/Companies/Block01'
import Faq from '@solid-ui-blocks/Faq/Block01'
// import Blog from '@solid-ui-blocks/Blog/Block01'
import Footer from '@solid-ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import theme from './styles/home/_theme'
import styles from './styles/home/_styles'
import { Helmet } from 'react-helmet'

const HomePage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout theme={theme} {...props}>
      <Helmet>
        <script>
          {` window.fwSettings={'widget_id' : 151000000911}
            !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()`}
        </script>
        <script
          type='text/javascript'
          src='https://widget.freshworks.com/widgets/151000000911.js'
          async
          defer
        ></script>
        <script
          async
          src='https://www.googletagmanager.com/gtag/js?id=AW-11036815270'
        ></script>
        <script>
          {` window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'AW-11036815270')`}
        </script>
        <script>
          {`
            window.faitracker=window.faitracker||function(){this.q=[];var t=new CustomEvent("FAITRACKER_QUEUED_EVENT");return this.init=function(t,e,a){this.TOKEN=t,this.INIT_PARAMS=e,this.INIT_CALLBACK=a,window.dispatchEvent(new CustomEvent("FAITRACKER_INIT_EVENT"))},this.call=function(){var e={k:"",a:[]};if(arguments&&arguments.length>=1){for(var a=1;a<arguments.length;a++)e.a.push(arguments[a]);e.k=arguments[0]}this.q.push(e),window.dispatchEvent(t)},this.message=function(){window.addEventListener("message",function(t){"faitracker"===t.data.origin&&this.call("message",t.data.type,t.data.message)})},this.message(),this.init("l4rlejjidnzrvmrbp3wcsw5bax7ajhpl",{host:"https://api.b3mxnuvcer.com"}),this}(),function(){var t=document.createElement("script");t.type="text/javascript",t.src="https://asset.b3mxnuvcer.com/b3mxnuvcer.js",t.async=!0,(d=document.getElementsByTagName("script")[0]).parentNode.insertBefore(t,d)}();
            `}
        </script>
      </Helmet>
      <Seo title='Tymeline ID' />
      {/* Modals */}
      <ModalWithTabs content={content['authentication']} reverse />
      <ModalWithTabs content={content['contact']} />
      <ModalWithTabs content={content['signup']} />
      <ModalSimple content={content['advertisement']} />
      {/* Blocks */}
      <Header content={content['header']} />
      <Divider space='5' />
      <Divider space='5' />
      <Hero content={content['hero']} reverse />
      <Divider space='5' />

      <Companies content={content['companies']} />
      <Divider space='3' />
      <Container variant='wide' sx={styles.featuresContainer}>
        {/* <Divider space={'1'} /> */}
        {/* <Stats content={content['stats']} /> */}
        {/* <Divider space='4' /> */}
        <Features content={content['features']} />
      </Container>
      <Divider space='5' />
      <HowItWorks content={content['how-it-works']} />
      <Divider space='5' />
      <Divider space='5' />
      <Container variant='wide' sx={styles.tabsContainer}>
        <Tabs space={4} variant='pill'>
          <FeatureTabOne content={content['feature-tab-one']} />
          <FeatureTabTwo content={content['feature-tab-two']} reverse />
          <FeatureTabThree content={content['feature-tab-three']} />
        </Tabs>
      </Container>
      <Divider space='4' />
      <Faq content={content['faq']} />
      {/* <Divider space='5' />
      <Divider space='5' /> */}
      {/* <Container variant='wide' sx={styles.testimonialsContainer}>
        <Testimonials content={content['testimonials']} />
        <Divider space='5' color='omegaLight' />
        <Companies content={content['companies']} />
      </Container> */}
      {/* <Divider space='5' />
      <Divider space='5' /> */}
      {/* <Blog content={content['latest-blogs']} /> */}
      {/* <Divider space='5' /> */}
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query HomePage {
    allBlockContent(filter: { page: { in: ["homepage/app", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default HomePage
