import React from 'react'
import SVG from 'react-inlinesvg'
import { Container, Flex, Box, css } from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import ContentText from '@solid-ui-components/ContentText'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import './overflow-hidden-style.css'

const styles = {
  logo: {
    width: [100, 150],
    height: [50, 75]
  }
}

const CompaniesBlock01 = ({ content }) => {
  const { text, collection, buttons } = content
  const containerRef = React.useRef(null)
  const scrollWidth = React.useRef(0)
  React.useEffect(() => {
    const container = containerRef.current

    // Calculate the total scroll width
    scrollWidth.current = container.scrollWidth - container.clientWidth

    // Function to auto-scroll to the next image
    const scrollNext = () => {
      container.scrollLeft += 1
      if (
        container.scrollLeft >=
        container.scrollWidth - container.clientWidth
      ) {
        container.scrollLeft = 0
      }
    }

    // Start auto-scrolling
    const intervalId = setInterval(scrollNext, 50) // Change the interval as needed (in milliseconds)

    // Clean up the interval when component unmounts
    return () => {
      clearInterval(intervalId)
    }
  }, [])

  return (
    <Container sx={{ textAlign: `center` }}>
      <ContentText content={text} />
      <a
        href='/privacy-policy/#definitions'
        className='scroll-heading-link'
        target='_blanc'
      >
        Companies that are <span className='expreience'>experiencing</span>{' '}
        Tymeline
      </a>
      {text && collection && <Divider />}
      <Flex
        className='logo-image-container'
        ref={containerRef}
        sx={{ m: -3, overflowX: 'auto' }}
      >
        {collection?.map(({ text, icon }, index) => (
          <Box
            key={`item-${index}`}
            sx={{ flexGrow: 1, p: [1, 3], minWidth: 'auto' }}
          >
            <Reveal
              effect='fadeInGrow'
              delay={0.2 * (index + 2)}
              title={text?.[0]?.text}
            >
              {icon?.src ? (
                // <SVG src={icon.src} css={css(styles.logo)} />
                <a href='/privacy-policy/#definitions' target='_blanc'>
                  <img src={icon.src} alt={icon.alt} className='logo-img' />
                </a>
              ) : (
                <ContentText content={text?.[0]} variant='h6' mb='0' />
              )}
            </Reveal>
          </Box>
        ))}
      </Flex>
      {buttons && (
        <>
          <Divider space={3} />
          <ContentButtons content={buttons} />
        </>
      )}
    </Container>
  )
}

export default WithDefaultContent(CompaniesBlock01)
